import website from "@/const/website";

const tagWel = website.fistPage;

const navs = {
    state: {tagWel: tagWel},
    actions: {}
}

export default navs;
